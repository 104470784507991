import request from '@/utils/request'
// 获取康复视频详情
export function getRehabilitationFile(params) {
    params.dataType='noKey';
    return request({
        url: '/rehabilitation/getRehabilitationFile',
        method: 'post',
        params:params
      })
  }

//  获取康复视频列表
export function getRehabilitations(params) {
    params.dataType='noKey';
    return request({
        url: '/rehabilitation/getRehabilitations',
        method: 'post',
        params:params
      })
  }

//  上传康复视频
export function uploadRehabilitationFile(params) {
    params.dataType='noKey';
    return request({
        url: '/rehabilitation/uploadRehabilitationFile',
        method: 'post',
        params:params
      })
  }

//  获取康复上传文件列表
export function getRehabilitationUploads(params) {
    params.dataType='noKey';
    return request({
        url: '/rehabilitation/getRehabilitationUploads',
        method: 'post',
        params:params
      })
  }

//  删除上传视频
export function deleteRehabilitationVideo(params) {
    params.dataType='noKey';
    return request({
        url: '/rehabilitation/deleteRehabilitationVideo',
        method: 'post',
        params:params
      })
  }


//   // 查询问卷结构
// export function getquestus(params) {
//     params.dataType='noKey';
//     return request({
//         url: '/ques/que',
//         method: 'post',
//         params:params
//       })
//   }
//
//
//   // 查询问卷数据
// export function getqueDatas(params) {
//     params.dataType='noKey';
//     return request({
//         url: '/ques/datas',
//         method: 'post',
//         params:params
//       })
//   }
//
//   export function saveDatas(params,data){
//     params.dataType='noKey';
//     return request({
//         url: '/ques/saveDatas',
//         method: 'post',
//         params:params,
//         data:data
//       })
//   }
//
//
//
//   export function pushques(params) {
//     params.dataType='noKey';
//     return request({
//         url: '/ques/push',
//         method: 'post',
//         params:params
//       })
//   }