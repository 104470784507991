<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="康复方案"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <div v-if="size >=0">
            <vrow v-for="index in size" :key="index" gutter="20" type="flex" justify="center" style="margin-top: 15px">
                <vcol span="12">
                    <div align="center">
                        <div v-if="(index-1)*2 <total">
                            <vimg
                                    width="100"
                                    height="100"
                                    src="https://img01.yzcdn.cn/vant/cat.jpeg"
                                    @click="toRehabilitationInfo(files[(index-1)*2].id)"
                            />
                            <div class="text">{{files[(index-1)*2].title}}</div>
                        </div>
                    </div>
                </vcol>
                <vcol span="12">
                    <div align="center">
                        <div v-if="(index-1)*2+1 <total">
                            <div class="text">{{images[(index-1)*2+1].note}}</div>
                            <vimg
                                    width="100"
                                    height="100"
                                    src="https://img01.yzcdn.cn/vant/cat.jpeg"
                                    @click="toRehabilitationInfo(files[(index-1)*2+1].id)"
                            />
                            <div class="text">{{files[(index-1)*2+1].title}}</div>
                        </div>
                    </div>
                </vcol>
            </vrow>
        </div>

        <empty v-if="nodata&&!user_empty" description="查无数据" />
        <Loading type="spinner" color="#1989fa" size="24px" vertical v-if="user_empty" style="margin-top: 40%">获取授权中...</Loading>

        <RLFooter/>
    </div>
</template>

<script>

    import {Empty,Image as vimg,ImagePreview,Col as vcol, Row as vrow,Loading,Toast } from 'vant';
    import {getRehabilitations} from '@/api/rehabilitation';
    // import {getBaseInfo} from '@/api/patient/baseinfo';
    import {setToken,removeToken} from '@/utils/auth';
    import {getUser} from '@/api/user';

    export default {
        name: 'QueIdx',
        components: {
            Empty,vimg,vcol,vrow,[ImagePreview.Component.name]: ImagePreview.Component,Loading
        },
        data(){
            return {
                active:0,
                files:[],
                nodata:true,
                activeTab: 0,
                total:0,
                ys:0,
                size:0,
                user:{
                    userName:'',
                    nickName:'',
                    idCard:'',
                    phone:'',
                    gender:'1',
                    birthday:'',
                    id:'',
                    pid:''
                },
                user_empty:true

            };
        },
        computed:{

        },
        methods:{
            initidx(){
                console.info(this.$store.state.user);
                this.$store.commit('updatePid',this.$route.params.pid);
                // let query = this.$route.query;

                // let follow_time=query.follow_time?query.follow_time : this.parseTime(new Date(),'{y}-{m}-{d}');
                getRehabilitations({pid:this.$route.params.pid}).then((response) => {
                    console.log(response)
                    if(response.code==200 ){
                        // this.loading=true;
                        this.total = response.data.total;
                        this.files=response.data.rows;
                        console.log(response.data)

                        this.ys = this.total%2
                        this.size = Math.ceil(this.total/2)

                    }
                });
                // getqueidxs({pid:this.$route.params.pid}).then((response) => {
                //     this.loading=true;
                //     if(this.followcrfs.length==0){
                //         this.followcrfs=response.data;
                //     }
                // });
            },
            showQue(idx){
                this.$router.push({path: '/que/'+idx.id});
            },
            formaterState(fins){
                if(fins==1){
                    return '未完成';
                }else{
                    return '已完成';
                }
            },
            formaterTime(ctime,uptime){
                let res="";
                if(ctime){
                    res+="创建时间："+this.parseTime(ctime);
                }
                if(uptime){
                    res+="    更新时间："+this.parseTime(uptime);
                }
                return res;
            },
            preImg(url){
                ImagePreview([url]);
            },
            toRehabilitationInfo(id){
                //tofollowup
                console.log(id)
                this.$router.push({path: '/rehabilitationInfo/',query:{id:id}});
            },
            initBaseInfo(){
                getRehabilitations({pid:this.user.id}).then((response) => {
                    console.log(response)
                    if(response.code==200 ){
                        // this.loading=true;
                        this.total = response.data.total;
                        this.files=response.data.rows;
                        console.log(response.data)

                        if(this.total == 0){
                            this.nodata = true
                        }else{
                            this.nodata = false
                        }

                        this.ys = this.total%2
                        this.size = Math.ceil(this.total/2)

                    }
                });
            },

        }
        ,
        created() {
            var query = this.$route.query;
            if(query.code || this.getQueryString('code')){
                console.log("code")
                console.log(query.code)
                console.log(this.getQueryString('code'))
                //this.$store.commit('updateOpenid','123456');
                this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
                setToken(query.code?query.code : this.getQueryString('code'));
                getUser().then((res) => {
                    if(res.data){
                        console.info(res.data.openid);
                        this.$store.commit('updateOpenid',res.data.openid);
                        this.$store.commit('updatePid',res.data.pid);
                        this.$store.commit('updateBaseInfo',res.data);
                        this.user.id=res.data.id;
                        this.user.pid=res.data.pid;
                        this.user.nickName=res.data.nickName;
                        this.user.userName=res.data.userName;
                        this.user.birthday=res.data.birthday;
                        this.user.gender=res.data.gender+'';
                        if(res.data.userStatus == 1){
                            this.user_empty = false
                            // let follow_time=this.parseTime(new Date(),'{y}-{m}-{d}');
                            this.initBaseInfo();

                        }else{
                            this.user_empty = true
                            Toast.fail('授权失败\n请注册信息');
                        }
                        // this.initBaseInfo();
                    }
                });
                //getBaseInfo({}).then((response) => {
                //   console.info(response);
                // });
            }else{
                removeToken();
            }
            /*初始化列表*/
            // this.initBaseInfo();

        },
    }
</script>

<style scoped>

    .is_completed{
        color: aqua;
    }
    .is_uncompleted{
        color:red;
    }

</style>
